import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import root from 'window-or-global';
import { buildLogger, LoggableError } from 'logger';
import StackTrace from 'stacktrace-js';
import { LOYALTY_REDEEM } from 'constants/font-awesome';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

import useStyles from 'apputil/view-styles';
import { getLoyaltyCard } from 'state/selectors';
import {
  Paper, Typography, Grid,
} from '@material-ui/core';
import InputPassData from './input-pass-data';

const UpdateLoyaltyRedeem = ({ passId }) => {
  const styles = useStyles();
  const logger = buildLogger();

  const [loading, setLoading] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [formError, setFormError] = useState(false);
  const [redeemValue, setRedeemValue] = useState(0);
  const [loyalty, setLoyalty] = useState({});

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();

  const { showNotification, types } = noticationContext;

  useEffect(() => {
    const onLoadLoyaltyCard = async () => {
      setLoading(true);
      const loyaltyData = getLoyaltyCard();
      setLoyalty(loyaltyData);
      setLoading(false);
    };
    onLoadLoyaltyCard();
    // eslint-disable-next-line
  }, []);

  const resetElement = (id) => {
    const element = root.document.getElementById(id);
    if (element) {
      element.value = '';
    }
  };

  const saveUpdatedRedeem = async () => {
    if (fieldError || !redeemValue) {
      setFormError(true);
    } else {
      try {
        setLoading(true);
        const { serialNumber } = loyalty;
        const getLoyaltyService = await import('services/loyalty-service');
        const { updateLoyaltyRedeem } = getLoyaltyService.default({
          stateContext,
          noticationContext,
        });
        const isUpdated = await updateLoyaltyRedeem({ redeem: redeemValue, passId, serialNumber });
        if (isUpdated) {
          logger.info({ loyalty, message: 'saveUpdatedRedeem' });
          const loyaltyData = await getLoyaltyCard();
          setLoyalty(loyaltyData);

          showNotification({
            message: `Loyalty redeemed. ${loyaltyData && loyaltyData.redeemAmount ? loyaltyData.redeemAmount : 0} left on the card.`,
            type: types.success,
          });
          resetElement('redeem-input');
        }
      } catch (err) {
        const trace = await StackTrace.get();
        const { stack = '', message = '' } = err;
        logger.error(new LoggableError({
          data: { stack, caller: 'saveUpdatedRedeem:onError' },
          message,
          trace,
        }));
      } finally {
        setLoading(false);
      }
    }
  };

  const {
    loyaltyAmount,
    requiredLoyaltyAmount,
    redeemAmount,
  } = loyalty;

  return (
    <Paper className={styles.paper} elevation={4}>
      <form autoComplete="off" id="update-loyalty-redeem">
        <Grid spacing={2}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={styles.grid}
          >
            <Grid item xs={12} sm={10} className={styles.topMarginTwo}>
              <Typography
                variant="h5"
                component="p" align="center">
                  {redeemAmount
                    ? `${redeemAmount} rewards are available to redeem on this Loyalty Card.`
                    : `There are no rewards available on this loyalty card. ${requiredLoyaltyAmount - loyaltyAmount} loyalty points needed.`
                  }
              </Typography>
            </Grid>
        </Grid>
          <InputPassData
            inputId="redeem-input"
            label="Reward Quantity"
            loading={loading}
            onSave={saveUpdatedRedeem}
            onfieldChange={(value) => {
              setFormError(false);
              setFieldError(isNaN(Number(value)));
              setRedeemValue(Number(value));
            }}
            fieldError={fieldError}
            formError={formError}
            fieldMessage={'The amount of loyalty to "Redeem"'}
            endIcon={LOYALTY_REDEEM}
            disable={redeemAmount === 0}
            buttonMessage="Update Redeem"
          />
        </form>
      </Paper>
  );
};

UpdateLoyaltyRedeem.propTypes = {
  passId: string.isRequired,
};

export default UpdateLoyaltyRedeem;
